import * as React from 'react'
import Layout from '../../components/layout'
import '../../styles/style.scss'

const HowWePrioritize = () => {
  return (
    <Layout pageTitle='Prioritization' nextBottomSlideBtn="See How It Works" nextBottomSlideBtnLink="/FacilityType" id="prioritization">
      <div style={{ display: "flex" }}>
        <div style={{ padding: "0em 2em", width: "50%" }}>
          <p>Southern 360 helps you prioritize projects that optimize your energy use and advance sustainability and resiliency goals. We leverage your data to identify the highest-impact opportunities to enable you to move forward confidently with implementing your carbon reduction strategy</p>
        </div>
        <div style={{ padding: "0em 2em", width: "50%" }}>
          <h1 style={{ marginTop: "-2em" }}>How We Prioritize</h1>
          <p style={{ animation: "fadeIn .75s ease-in-out forwards", opacity: 0 }}><strong>Step 1:</strong> We start with your organization’s goals and needs, and then analyze your facilities’ energy use to identify areas for energy optimization</p>
          <br/>
          <p style={{ animation: "fadeIn .75s ease-in-out 1s forwards", opacity: 0 }}><strong>Step 2:</strong> We then prioritize potential targeted energy efficiency, renewable and storage, and electric vehicle projects based on your facilities’ energy usage and goals</p>
          <br/>
          <p style={{ animation: "fadeIn .75s ease-in-out 2s forwards", opacity: 0 }}><strong>Step 3:</strong> Southern 360 can help you implement and maintain projects</p>
        </div>
      </div>
    </Layout>
  )
}

export default HowWePrioritize